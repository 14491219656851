import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Button from './Button';
import Api, {Cookie} from '../Api';

const Intro = styled.div`    
    width: 60%;
    margin-left: 20%;
    margin-top: 4em;
    text-align: center;

    @media (max-width: 1024px) {
        margin: auto;
        width: 100%;
    }
`;

const btnCSS = css`
    margin-top: 2em;
`;


const Start = ({props}) => {

    const [quiz, setQuiz] = useState(null);
    const [loaded, setLoad] = useState(false);
    const [email, setEmail] = useState(Cookie('meEmail'));
    const [isValid, setIsValid] = useState(false);
    const [starting, setStarting] = useState("Passer le test ...");

    useEffect(() => {
        Api.get(`Setting/2/Quiz`)
            .then(response => {
                //alert('response.data.Title')
                setQuiz(response.data)
                setLoad(true)
            })
            .catch(err => console.error(err))
    }, []);

    const startQuiz = (event) => {
        //var item = JSON.stringify({Id:'0', Email: event.target.Email.value})
        //console.log('item',item)
        document.cookie = `meEmail=${email}`
 
        Api.post('Participant', {
            Id: '0',
            Email: email
          })
        .then(response => {
            props(response.data.Id)
            //console.log('data',response.data)
            
            //alert(response.data.Id)
            localStorage.setItem('LeadMeUp',JSON.stringify({
                started:true,
                session:response.data.Id,
            }))
            
            
            }
        )
        .catch(err => console.error({ err }))
}

const emailValid = (text) => {
    if(text.length>4 && text.includes("@") && text.includes("."))
    {
        const emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(text)) {
            setIsValid(true);
            return true;
        }
    }   
    setIsValid(false);
    return false
}

const emailChange = (e) => {
    setEmail(e.target.value)
    //alert(e.target.value)
    if(emailValid(e.target.value))
        setStarting("Passer le test")
    else
        setStarting("Passer le test ...")
    
}

    return (
        <div>
{   loaded ?      
        <Intro>
            <h1 style={{color:'#df6058', padding:'1rem 0'}}>{quiz.Title}</h1>
            <h4 style={{color:'#df6058'}}>{quiz.Description}</h4>
            <h5 style={{padding:'2rem 0', marginLeft:'20%' ,width:'60%'}}>Pour chacunes des 24 affirmations, choisissez l'objectif qui vous correspond le plus (+) et celui qui vous correspond le moins (-)</h5>

            <div style={{ fontSize:'1.5em', height: '50%', width:'100%', padding:'5px', marginTop:'30px', marginBottom:'30px'}}>
            <label style={{ color:'#919A94'}}>Adresse mail </label>
            <input type="text" name="Email" defaultValue={email} style={{fontSize:'1.5em', color:'#161A31'}} onChange={emailChange}/>
            </div>

            <Button onClick={startQuiz} css={btnCSS} style={ isValid ? {borderColor:'blueviolet', color:'white'} : {pointerEvents:'none', cursor:'not-allowed'}}>{starting}</Button>
        </Intro>
: 
<Intro>
<h1>DEFI Test ...</h1>
</Intro>
}
        </div>
    )
}

export default Start
