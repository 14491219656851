import React, { useState } from 'react'
import './App.css'
import Start from './components/Start'
import Quiz from './components/Quiz'

function App() {

  const [sessionId, setSession] = useState(0);

  return (
    <div className="quiz">
      { (sessionId>0) ? <Quiz props={sessionId} /> : <Start props={setSession} />} 
    </div>
  );
}

export default App;
