import React from 'react';
import Navbar from './Navbar';

const Header = () => {
    return (
        <div className="Header">
            <img src='logo.png' alt="Logo" height="100%"/>
            <Navbar />
            
        </div>
    )
}

export default Header;