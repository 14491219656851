import axios from 'axios';

export default axios.create({

  baseURL: `https://quiz.digitalservices.ma/`
  //baseURL: `https://api.echchatt.net/`
  //baseURL: `https://localhost:44375/`
  //baseURL: `https://opentdb.com/`

});

export const Cookie = (key) => {
  let name = (key + "=")
  let cookies = decodeURIComponent(document.cookie)
  //console.log('cookies',key,cookies)

  let ca = cookies.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}
