import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import QuizOver from './QuizOver';
import Button from './Button';

import Api from '../Api';

const QuizWindow = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 7vh;
`;

const Options = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 2em auto;

    @media screen and (min-width: 1180px) {
        width: 50%;
    }
`;

const Option = styled.button`
    display: block;
    border: 1px solid #616A94;
    border-radius: 15px;
    padding: 15px 30px;
    text-decoration: none;
    color: #616A94;
    background-color: #161A31;
    transition: 0.3s;
    font-size: 1em;
    outline: none;
    user-select: none;
    margin: 0.5em;
    
    pointer-events: none;
    cursor: not-allowed;

    &:hover {
        color: white;
        background-color: #616A94;
    }
`;

const Response = styled.button`
    display: block;
    border: 3px solid;
    border-radius: 15px;
    padding: 10px;
    text-decoration: none;
    color: #616A94;
    background-color: #161A31;
    transition: 0.3s;
    font-size: 1em;
    outline: none;
    user-select: none;
    cursor: pointer;

    &:hover {
        font-weight: bold;
        color: white;
        background-color: #616A94;
    }
`;

const Question = styled.div`
    width: 70%;
    font-size: 1.5em;
    margin: 0 auto;
    color: #df6058;
`;

const Consigne = styled.div`
    width: 70%;
    font-size: 0.7em;
    margin: 0 auto;

`;

const Quiz = ({props}) => {
    const [number, setNumber] = useState(0);
    const [quiz, setQuiz] = useState([]);
    const [plus, setPlus] = useState(0);
    const [moins, setMoins] = useState(0);
    const [now, setTime] = useState(new Date());
    const [loaded, setLoad] = useState(false);
    //const [checked, setCheck] = useState(false);

    const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);
    

const postAnswer = (e) => {
    setLoad(false)
    var responses = [{
        SessionId:props,
        ResponseId : plus,
        Measure : 1,
        Created:now
    }, {
        SessionId:props,
        ResponseId : moins,
        Measure : -1,
        Created:now
    }]

    setPlus(0)
    setMoins(0)

    Api.post('Performance', responses)
    .then(response => {
        //console.log('data',response.data)
        setNumber(number + 1)
        setTime(new Date())
        setLoad(true)

    })
    .catch(err => console.error({ err }))
}

    useEffect(() => {
        setPlus(0)
        setMoins(0)
        setLoad(false)

        Api.get('Quiz/2')
            .then(response => {
                //alert('response.data.Title')
                setQuiz(response.data.questions.map(item => (

                    {
                        Id:item.Id,
                        question: item.Title,
                        description: item.Description,
                        options : shuffle(item.responses)
                    }

                )))
                setLoad(true)
            })
            .catch(err => console.error(err))

    }, [])


    return (
        <QuizWindow>
            { quiz[number] &&

                <>
                    <Question dangerouslySetInnerHTML={{ __html: `${quiz[number].question} (${number+1}/24)` }}></Question>                    
                    
                    <Options>

<table>
<thead>
    <tr>
        <th style={{width: '50px'}}/>        
        <th /> 
        <th style={{width: '50px'}}/>
    </tr>
</thead>

<tbody>

                        {quiz[number].options.map((item, index) => (
                            <tr key={index}>
        <td>
            <Response style={plus===item.Id ? {backgroundColor: 'blueviolet'} : {borderColor: 'blueviolet'}} onClick={() => {(moins===item.Id) ? setMoins(0): setPlus(item.Id)}}>+</Response>
        </td>
        <td>
            <Option key={item.Id} dangerouslySetInnerHTML={loaded ? { __html: item.Title }:{ __html: null }} style={plus===item.Id ? {backgroundColor:'blueviolet', color:'white'} : (moins===item.Id ? {backgroundColor:'gray', color:'black'} : {})}></Option>
        </td>

        <td>
            <Response style={moins===item.Id ?{backgroundColor: 'gray'}:{borderColor: 'gray'}} onClick={() => {(plus===item.Id) ? setPlus(0): setMoins(item.Id)}}>-</Response>
        </td>

                            </tr>
                        ))}

</tbody>
</table>

                    </Options>

                    {loaded && plus>0 && moins>0 ?
                    <Button onClick={postAnswer}>Confirmer</Button>
                    : 
                    <Consigne dangerouslySetInnerHTML={{ __html: quiz[number].description }}></Consigne>
                    }

                      

                </>

            }
            {
                number === 24 && <QuizOver sessionId={props} />
            }
        </QuizWindow>
    )
}

export default Quiz
