import React from "react";
import ImgsViewer from "react-images-viewer-updated";


export default class ImagesViewer extends React.Component {
    constructor (props){
        super(props);
        this.state = {items:[], currImg:0, viewerIsOpen:false};
    }

    componentDidMount(){
        this.setState({items : [
            { src: "Docs/"+this.props.result+"/1.jpg" },
            { src: "Docs/"+this.props.result+"/2.jpg" },
            { src: "Docs/"+this.props.result+"/3.jpg" },
            { src: "Docs/"+this.props.result+"/4.jpg" },
          ]})       
        
        this.setState({viewerIsOpen:true})
        //alert(this.state.items[0].src)
    }

    //componentDidUpdate() { }

    componentWillUnmount() {
        this.setState({items:null})        
    }

    closeViewer =() => {
        this.setState({viewerIsOpen:false})
        this.setState({currImg:0})
    }

    gotoPrevious =() => {
        if(this.state.currImg>0)
            this.setState({currImg:this.state.currImg-1})
        else 
            this.setState({currImg:3})
    }

    gotoNext =() => {
        if(this.state.currImg<3)
            this.setState({currImg:this.state.currImg+1})
        else 
            this.setState({currImg:0})
    }

    render() {
    return (
      <ImgsViewer
        imgs={this.state.items}
        currImg={this.state.currImg}
        isOpen={this.state.viewerIsOpen}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        onClose={this.closeViewer}
      />
    )
}
}

