import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button} from './Button';
import ImagesViewer from './ImagesViewer';

import Api from '../Api';

const Title = styled.h1`
    margin-top: 0;
    font-size: 48px;
    color: #df6058;
`;


const QuizOver = ({sessionId}) => {

    const [result, setResult] = useState(null);
    const [loaded, setLoad] = useState(false);

    const refreshPage = () => window.location.reload();

    const getResult = () => {
        return new Promise(resolve => setTimeout(resolve, 5000)) 
      }

    useEffect(() => {
        Api.get(`Result/${sessionId}`)
            .then(response => {
                //alert(response.data.Title)
                setResult(response.data)
                //setTimeout(() => { ele.outerHTML = '' },3000)
                getResult().then( () => {
                    setLoad(true)
                })
            })
            .catch(err => console.error(err))

    }, [sessionId]);

    let loadResult ="Nous sommes entrain d'étudier votre profil ..."

    return (
        <>
        {loaded ?
        <div>
 
 <ImagesViewer result={result.Page} />
 
 {/*
            <div className="Page">
                <object data={"Docs/"+result.Page+".pdf"} title={result.Page} type="application/pdf" width="100%" height="100%">
                    <img src={"Docs/"+result.Page+".jpg"} alt={result.Title} height="100%" />
                </object>
            </div>
*/}
            <Button onClick={refreshPage}>Refaire le Test</Button>
            
        </div>
        :
        <div>
            <Title>{loadResult}</Title>
            <img src={"loading.gif"} alt={loadResult} style={{borderRadius: '50%'}} />
        </div>
        }
            
        </>
    )
}

export default QuizOver

/*
            <p>La dominante de votre personnalité est :</p>
            <Title>{result.Title}</Title>

    <a href={"Docs/"+result.Page+".pdf"} download><Button>Click to download</Button></a>
*/