import React from 'react';
import {Button} from './Button'

const Navbar = () => {
    const refreshPage = () => window.location.reload();
    
    return (
        
        <div className="Navbar">
            <Button onClick={refreshPage}>Accueil</Button>
        </div>
    )
}

export default Navbar;